import React from 'react';
import { Link } from 'gatsby';

import { StyledLink } from './layout/typography';

import formatGCMSDate from '../utils/date';

export default function Post({ post }) {
  let formattedDate = formatGCMSDate(post.date);

  return (
    <div key={post.title}>
      <div className="h-full flex flex-col justify-between">
        <div>
          <Link to={`/blog/${post.slug}`} className="block mt-4">
            <p className="text-base text-gray-500 leading-8">{formattedDate}</p>
            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
            <p className="mt-3 text-base text-gray-500">{post.excerpt}</p>
          </Link>
        </div>
        <div className="mt-3">
          <Link
            to={`/blog/${post.slug}`}
            className="text-base font-semibold text-timothy-blue hover:text-blue-500"
          >
            <StyledLink>View post &rarr;</StyledLink>
          </Link>
        </div>
      </div>
    </div>
  );
}
