import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import { Headline } from '../layout/typography';

import Post from '../post';

export default function Blog() {
  const {
    allGraphCmsPost: { nodes: posts },
  } = useStaticQuery(graphql`
    query BlogPreview {
      allGraphCmsPost(
        limit: 3
        sort: { fields: date, order: DESC }
        filter: { stage: { eq: PUBLISHED } }
      ) {
        nodes {
          id
          date
          excerpt
          slug
          title
          tags {
            ... on GraphCMS_Tag {
              id
              name
              textColor {
                hex
              }
              backgroundColor {
                hex
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section
      id="blog"
      className="bg-white pt-16 pb-4 px-4 sm:px-6 lg:pt-24 lg:pb-4 lg:px-8"
    >
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <Headline className="text-7xl tracking-tight font-extrabold sm:text-9xl">
            Blog
          </Headline>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Since my main job is building tech products I figured: Why not make
            a habit out of sharing the juicy parts? That&apos;s why I
            (semi-)regularly write on my personal blog!
          </p>
        </div>
        <div className="my-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => {
            return <Post key={post.id} post={post} />;
          })}
        </div>
        <div className="py-12 sm:mt-8 sm:flex sm:justify-center lg:justify-end">
          <div className="mt-3 sm:mt-0">
            <Link
              to="blog"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-timothy-blue hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
            >
              View All Posts
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
