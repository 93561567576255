import React, { useState } from 'react';
import { Link } from 'gatsby';
import { XCircleIcon } from '@heroicons/react/solid';
import { Success } from './success';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const encode = (data) => {
  const encoded = new URLSearchParams(data).toString();
  return encoded;
};

const Form = () => {
  const [mail, setMail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  function handleMailChange({ target: { value } }) {
    setMail(value);
  }

  function isValidMail(email) {
    var emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email.match(emailRegex)) {
      return false;
    }

    return true;
  }

  function handleSubmit(e) {
    if (!isValidMail(mail)) {
      setError('Bitte geben Sie eine gültige E-Mail-Adresse ein');
      e.preventDefault();
      return;
    }

    fetch(
      'https://1ab8a1e7.sibforms.com/serve/MUIEAH7l1CvsGCZLgGUfAzsSfKAlIQYvW4Exo6ZCizseusmQeg0oN4ErI6jjYnZ1ChNlbCf8bNqeh-KCN1m54sekGNldFt-JxJW86PdbwhJUREJhxTGNodTrnyt2GDuprXJNObasfC5nt794K-8oeDFudfjfu4OVyxIv8vgZ0X-ADXCdgPKD_ZTFeUBZ43sSguUOQmh9Ykz1Qjy1',
      {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ EMAIL: mail }),
      }
    )
      .then(() => setSuccess(true))
      .catch((error) => setError('An error occured. Please try again later!'));

    e.preventDefault();
  }

  const enabled = !!mail;

  return (
    <div id="form" className="mt-4">
      {success ? (
        <Success />
      ) : (
        <form
          id="sib-form"
          method="POST"
          className="sm:max-w-xl sm:mx-auto lg:mx-0"
          onSubmit={handleSubmit}
        >
          <div className="sm:flex mt-3">
            <div className="min-w-0 flex-1">
              <label htmlFor="email" className="sr-only">
                E-Mail Address
              </label>
              <input
                id="EMAIL"
                name="EMAIL"
                type="email"
                autoComplete="email"
                value={mail}
                onChange={handleMailChange}
                placeholder="Enter E-Mail Address"
                className="py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md"
              />
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <button
                type="submit"
                className={classNames(
                  enabled
                    ? 'text-white bg-purple-800 hover:bg-purple-700 cursor-pointer'
                    : 'text-gray-400 bg-gray-200 hover:bg-gray-200 cursor-not-allowed',
                  'block w-full py-3 px-4 rounded-md shadow text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300 focus:ring-offset-gray-900'
                )}
              >
                Subscribe!
              </button>
            </div>
          </div>
          {!!error && (
            <div className="rounded-md bg-red-50 p-4 mt-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-red-800">{error}</p>
                </div>
              </div>
            </div>
          )}
          <p className="mt-3 text-sm text-purple-300">
            I care about the protection of your data. By pressing
            &quot;Subscribe&quot;, you agree to receiving updates on my products
            and blog posts via mail (max. 1 per week). You can unsubscribe at
            any time. For further information, please read the{' '}
            <Link
              to="/privacy"
              className="text-purple-100 font-medium underline"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </form>
      )}
    </div>
  );
};

export default Form;

