export default function formatGCMSDate(date) {
  return new Date(`${date}T06:00:00Z`).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function formatGCMSDateShort(date) {
  return new Date(`${date}T06:00:00Z`).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
  });
}
