import React from 'react';

import Signup from '../signup';

export default function Subscribe() {
  return (
    <section id="subscribe" className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <Signup />
      </div>
    </section>
  );
}
