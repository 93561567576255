import React from 'react';

import Form from './form';

export default function Signup() {
  return (
    <div
      id="subscribe"
      className="py-10 px-6 bg-timothy-purple rounded-3xl sm:py-16 sm:px-12 lg:p-20 lg:flex lg:items-center gap-x-10"
    >
      <div className="lg:w-0 lg:flex-1">
        <p className="text-center lg:text-left max-w-3xl text-lg text-purple-100">
          Subscribe to my product journey.
        </p>
        <h2 className="mt-4 lg:mt-0 text-center lg:text-left text-3xl font-extrabold tracking-tight text-white">
          Honest insights of somebody who
          <span className="text-purple-300">
            {' '}
            validates product ideas for a living. Subscribe!
          </span>
        </h2>
      </div>
      <div className="lg:w-0 lg:flex-1 mt-10 lg:mt-0">
        <Form />
      </div>
    </div>
  );
}
